<template>
  <div>
    <b-field grouped class="has-margin-bottom-25">
      <b-input :value="affLink" expanded readonly />
      <p class="control">
        <b-button
          type="is-info"
          icon-left="link"
          @click="$bus.$emit('copyToClipboard', affLink)"
          >Copy unique link</b-button
        >
      </p>
    </b-field>
    <p class="has-text-grey">
      &#8618; Redirects to
      <a
        :href="$_.get(affLinkData, 'redirectUrl', defaultRedirectUrl)"
        target="_blank"
        class="has-text-grey"
        ><u>{{ $_.get(affLinkData, "redirectUrl", defaultRedirectUrl) }}</u></a
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "AffLinkInput",
  inject: ["affAccountProviderData"],
  data() {
    return {
      defaultRedirectUrl: "https://fixed.net"
    };
  },
  computed: {
    affLink() {
      return this.affAccountProviderData().affLink;
    },
    affLinkData() {
      return this.affAccountProviderData().affLinkData;
    }
  }
};
</script>
